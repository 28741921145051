<nav [ngClass]="{ 'md:bg-opacity-0': !scrolling, 'md:bg-opacity-100': scrolling  }"  class="fixed transition-all bg-white w-full z-20 top-0 start-0">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <a href="https://altewerkstatt-wallau.de/" class="flex items-center space-x-3 rtl:space-x-reverse">
      <img src="https://cdn.polynode.de/altewerkstatt-wallau/logo.png"
        [ngClass]="{ 'h-24': !scrolling, 'h-12': scrolling  }" class="transition-all"
        alt="Alte Werkstatt Wallau Logo" />
    </a>
    <div class="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
      <button data-collapse-toggle="navbar-sticky" type="button"
        class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        aria-controls="navbar-sticky" aria-expanded="false">
        <span class="sr-only">Hauptmenü öffnen</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15" />
        </svg>
      </button>
    </div>
    <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
      <ul
        class="flex flex-col p-4 md:p-0 mt-4 font-medium  md:space-x-1 md:flex-row md:mt-0">
        @for (link of links; track link.name) {
        <li>
          <a [routerLink]="link.url" class="link" routerLinkActive="link-active">{{ link.name }}</a>
        </li>
        }
      </ul>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>

<footer class="bg-white w-full">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-12 px-4">
    <div>© Alte Werkstatt Wallau</div>
    <div class="flex flex-wrap items-end gap-4">
      <a routerLink="customers" class="link">Kundenbereich</a>
      <a routerLink="imprint" class="link">Impressum</a>
      <a routerLink="privacy" class="link">Datenschutzerklärung</a>
    </div>
  </div>
</footer>

<app-modal></app-modal>