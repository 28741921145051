import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent),
  },
  {
    path: 'gallery',
    loadComponent: () => import('./pages/gallery/gallery.component').then(m => m.GalleryComponent),
  },
  {
    path: 'equipment',
    loadComponent: () => import('./pages/equipment/equipment.component').then(m => m.EquipmentComponent),
  },
  {
    path: 'price',
    loadComponent: () => import('./pages/price/price.component').then(m => m.PriceComponent),
  },
  {
    path: 'conditions',
    loadComponent: () => import('./pages/conditions/conditions.component').then(m => m.ConditionsComponent),
  },
  {
    path: 'booking-information',
    loadComponent: () => import('./pages/booking-information/booking-information.component').then(m => m.BookingInformationComponent),
  },
  {
    path: 'customers',
    loadComponent: () => import('./pages/members/members.component').then(m => m.MembersComponent),
  },
  {
    path: 'private',
    loadComponent: () => import('./pages/members/private/private.component').then(m => m.PrivateComponent),
    canActivate: [authGuard],
  },
  {
    path: 'private/new',
    loadComponent: () => import('./pages/members/new/new.component').then(m => m.NewComponent),
    canActivate: [authGuard],
  },
  {
    path: 'private/list',
    loadComponent: () => import('./pages/members/overview/overview.component').then(m => m.OverviewComponent),
    canActivate: [authGuard],
  },
  {
    path: 'private/list/:id',
    loadComponent: () => import('./pages/members/detail/detail.component').then(m => m.DetailComponent),
    canActivate: [authGuard],
  },
  {
    path: 'private/upload',
    loadComponent: () => import('./pages/members/upload/upload.component').then(m => m.UploadComponent),
    canActivate: [authGuard],
  },
  {
    path: 'private/setting',
    loadComponent: () => import('./pages/members/setting/setting.component').then(m => m.SettingComponent),
    canActivate: [authGuard],
  },
  {
    path: 'imprint',
    loadComponent: () => import('./pages/imprint/imprint.component').then(m => m.ImprintComponent),
  },
  {
    path: 'privacy',
    loadComponent: () => import('./pages/privacy/privacy.component').then(m => m.PrivacyComponent),
  },
];
