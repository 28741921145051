import { NgClass } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { ModalComponent } from './components/modal/modal.component';

interface HeaderLinks {
  name: string;
  url: string;
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgClass, RouterLink, RouterLinkActive, ModalComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  scrolling = false;

  links: HeaderLinks[] = [
    {
      name: 'Home',
      url: 'home'
    },
    {
      name: 'Galerie',
      url: 'gallery'
    },
    {
      name: 'Ausstattung',
      url: 'equipment'
    },
    {
      name: 'Preise',
      url: 'price'
    },
    {
      name: 'Mietbedingungen',
      url: 'conditions'
    }
  ]

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.checkNavbarBackground();
  }

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkNavbarBackground();
      }
    });
  }

  ngOnInit(): void {
    initFlowbite();
  }

  private checkNavbarBackground() {
    if (window.scrollY > 75) {
      this.scrolling = true;
    } else {
      this.scrolling = false;
    }
  }
}
